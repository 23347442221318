import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"id":"lock","fill-height":"","tag":"section"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VSlideYTransition,{attrs:{"appear":""}},[_c(VCard,{staticClass:"mt-6 mt-md-0",attrs:{"light":"","max-width":"100%","width":"250"}},[_c(VCardText,{staticClass:"text-center"},[_c(VAvatar,{staticClass:"mt-n12 mb-6 elevation-12",attrs:{"color":"grey","size":"88"}}),_c('div',{staticClass:"display-2 font-weight-light mb-3 text--darken-2 grey--text"},[_vm._v(" Tania Andrew ")]),_c(VTextField,{staticClass:"mb-8",attrs:{"color":"secondary","label":"Enter Password"}}),_c('pages-btn',[_vm._v(" Unlock ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }